// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-common-footer-tsx": () => import("./../../../src/pages/common/footer.tsx" /* webpackChunkName: "component---src-pages-common-footer-tsx" */),
  "component---src-pages-common-heroshot-tsx": () => import("./../../../src/pages/common/heroshot.tsx" /* webpackChunkName: "component---src-pages-common-heroshot-tsx" */),
  "component---src-pages-common-navbar-tsx": () => import("./../../../src/pages/common/navbar.tsx" /* webpackChunkName: "component---src-pages-common-navbar-tsx" */),
  "component---src-pages-common-resume-tsx": () => import("./../../../src/pages/common/resume.tsx" /* webpackChunkName: "component---src-pages-common-resume-tsx" */),
  "component---src-pages-homepage-contact-form-tsx": () => import("./../../../src/pages/homepage/contactForm.tsx" /* webpackChunkName: "component---src-pages-homepage-contact-form-tsx" */),
  "component---src-pages-homepage-contacto-tsx": () => import("./../../../src/pages/homepage/contacto.tsx" /* webpackChunkName: "component---src-pages-homepage-contacto-tsx" */),
  "component---src-pages-homepage-portfolio-tsx": () => import("./../../../src/pages/homepage/portfolio.tsx" /* webpackChunkName: "component---src-pages-homepage-portfolio-tsx" */),
  "component---src-pages-homepage-services-old-tsx": () => import("./../../../src/pages/homepage/servicesOld.tsx" /* webpackChunkName: "component---src-pages-homepage-services-old-tsx" */),
  "component---src-pages-homepage-services-tsx": () => import("./../../../src/pages/homepage/services.tsx" /* webpackChunkName: "component---src-pages-homepage-services-tsx" */),
  "component---src-pages-homepage-tagline-tsx": () => import("./../../../src/pages/homepage/tagline.tsx" /* webpackChunkName: "component---src-pages-homepage-tagline-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

